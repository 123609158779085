<template>
  <v-card flat>
    <v-alert
      v-if="fixtureDev && p100Count > 0 && (p75Count > 0 || p50Count > 0)"
      dark
      dense
      color="secondary"
      class="mx-3 text-center"
    >
      <v-icon dark class="mr-2">mdi-alert</v-icon>
      An R100 is selected. In Development Fixtures, these are not compatible
      with R75 or R50 receptacles because of their Probe heights.
    </v-alert>
    <v-container fluid>
      <v-layout v-if="dutConfigs && dutConfigs.length > 0" row>
        <DutConfigItem
          v-for="dutConfig in dutConfigs"
          :key="dutConfig.pk"
          :dutConfig="dutConfig"
          :fixtureState="fixtureState"
          :isAdmin="isAdmin"
        />
      </v-layout>
      <v-layout v-else class="justify-center my-3" row>
        <span class="body-2 font-weight-bold">No DUT Configs to display</span>
      </v-layout>
    </v-container>
  </v-card>
</template>
<script>
export default {
  name: "DutConfigBlock",
  components: {
    DutConfigItem: () => import("@/modules/dutconfigs/components/DutConfigItem.vue"),
  },
  props: {
    dutConfigs: {
      type: Array,
      default: null,
    },
    fixtureDev: {
      type: Boolean,
      default: null,
    },
    p100Count: {
      type: Number,
      default: null,
    },
    p75Count: {
      type: Number,
      default: null,
    },
    p50Count: {
      type: Number,
      default: null,
    },
    fixtureState: {
      type: Number,
      default: null
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
